html,
body,
#root {
  height: 100%;
}

.pf-l-gallery {
  --pf-l-gallery--GridTemplateColumns--min: 600px
}

div.pf-c-back-to-top.back_to_top {
  position: fixed;
  z-index: 999999;
}

div.pf-c-banner.footer_banner {
  --pf-c-banner--BackgroundColor: #fff;
}

.footertext {
  color: #4A4A4A;
}

.pf-c-page__header {
  min-height: 72px;
}


.pf-c-page__main-section.main_content_section{
  padding-top: 10px;
}



div.pf-c-masthead__content td{
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: nowrap;

}